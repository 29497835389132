import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllLimitDateExtensionRequests = () => {
  return ApiService.get("limit-date-extensions");
};

/**
 * GET request to fetch data by Id
 * @param request_id
 * @returns {*}
 */
export const getLimitDateExtensionRequestById = (request_id) => {
  return ApiService.get("limit-date-extensions", request_id);
};

/**
 * GET request to fetch data by user id
 * @param userId
 * @returns {*}
 */
export const getLimitDateExtensionRequestByUserId = (userId) => {
  return ApiService.get("limit-date-extensions/user", userId);
};

/**
 * GET request to fetch data with oldest request trackings
 * @returns {*}
 */
export const getLimitDateExtensionRequestWithOldestRequestTracking = () => {
  return ApiService.get("limit-date-extensions/tracking/oldest");
};

/**
 * GET request to fetch data by user id
 * @param userId
 * @returns {*}
 */
export const getWhereLastTrackingIsAcceptedOrRejectedByUserId = (userId) => {
  return ApiService.get(
    "limit-date-extensions/tracking/latest/status/accepted-rejected/user",
    userId
  );
};

/**
 * POST request to push request request
 * @param request
 * @returns {*}
 */
export const createLimitDateExtensionRequest = (request) => {
  return ApiService.post("limit-date-extensions", request);
};

/**
 * POST request to review limit date extension request
 * @param payload
 * @returns {*}
 */
export const reviewLimitDateExtensionRequest = (payload) => {
  return ApiService.post("limit-date-extensions/review", payload);
};

export default {
  getAllLimitDateExtensionRequests,
  getLimitDateExtensionRequestById,
  createLimitDateExtensionRequest,
  reviewLimitDateExtensionRequest,
  getLimitDateExtensionRequestByUserId,
  getLimitDateExtensionRequestWithOldestRequestTracking,
  getWhereLastTrackingIsAcceptedOrRejectedByUserId,
};
