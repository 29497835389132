import ApiService from "@/core/services/api.service";

/**
 * PUT request to update a specific grade
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateScoreStudentSubEvaluation = (id, payload) => {
    return ApiService.update("score-student-sub-evaluations", id, payload);
};

export default {
    updateScoreStudentSubEvaluation,
};
