import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllRequestReasons = () => {
  return ApiService.get("request-reasons");
};

/**
 * GET request to fetch data by Id
 * @param request_reason_id
 * @returns {*}
 */
export const getRequestReasonById = (request_reason_id) => {
  return ApiService.get("request-reasons", request_reason_id);
};

/**
 * POST request to push request reasons
 * @param payload
 * @returns {*}
 */
export const createRequestReason = (payload) => {
  return ApiService.post("request-reasons", payload);
};

/**
 * UPDATE request to push updated request reasons
 * @param request_reason_id
 * @param payload
 * @returns {*}
 */
export const updateRequestReason = (id, payload) => {
  return ApiService.update("request-reasons", id, payload);
};

/**
 * DELETE request to delete request reasons
 * @param request_reason_id
 * @returns {*}
 */
export const deleteRequestReason = (request_reason_id) => {
  return ApiService.delete("request-reasons", request_reason_id);
};

/**
 * GET request to fetch data by request clasification
 * @param request_reason_id
 * @returns {*}
 */
export const getRequestReasonsByRequestClasificationId = (
  request_clasification_id
) => {
  return ApiService.get(
    "request-reasons/request-clasification",
    request_clasification_id
  );
};

export default {
  getAllRequestReasons,
  getRequestReasonById,
  createRequestReason,
  updateRequestReason,
  deleteRequestReason,
  getRequestReasonsByRequestClasificationId,
};
