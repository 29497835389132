<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    persistent
    max-width="675"
  >
    <v-form>
      <v-card>
        <v-toolbar dark color="teal" class="elevation-0 px-3">
          <span class="headline">Solicitar extensión de fecha límite</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-card outlined class="mx-4 mb-4 px-5 py-4 d-flex flex-column flex-sm-row rounded-lg justify-center align-start" color="teal lighten-5">
                  <v-icon x-large color="teal">
                    mdi-information
                  </v-icon>
                  <div class="px-4">
                    <p class="m-0 text-body-1">
                      Es importante señalar que este cambio de fecha es independiente del grupo que estás consultando actualmente. Esto significa que si necesitas ingresar calificaciones para varios grupos, solo tendrás que procesar una solicitud de extensión única que será aplicable a todos los grupos. Si tienes dudas sobre si ya has solicitado una extensión para este perfil, puedes verificarlo <span class="font-weight-bold teal--text" style="cursor: pointer;" @click="goToMyRequests">aquí</span>.
                    </p>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12" class="pb-0">
                <form-group
                  name="razón de la solicitud"
                  :validator="$v.limitDateExtensionRequest.request_reason_id"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Razón de la solicitud de extensión de perfil"
                    placeholder="Razón de la solicitud"
                    :items="requestReasons.data"
                    :loading="requestReasons.isLoading"
                    :disabled="!requestReasons.data.length"
                    item-text="name"
                    item-value="id"
                    required
                    filled
                    rounded
                    dense
                    background-color="grey lighten-4"
                    color="black"
                    v-model="limitDateExtensionRequest.request_reason_id"
                  ></v-select>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group
                  name="justificación"
                  :validator="$v.limitDateExtensionRequest.justification"
                >
                  <v-textarea
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    filled
                    rounded
                    dense
                    background-color="grey lighten-4"
                    color="black"
                    counter
                    label="Justificación"
                    auto-grow
                    rows="3"
                    v-model="limitDateExtensionRequest.justification"
                  ></v-textarea>
                </form-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cancelar</v-btn>
          <v-btn color="teal" depressed :loading="isLoading" @click="submit"
            dark>Solicitar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import limitDateExtensionRepository from "@/repositories/limitDateExtensionRepository";
import requestReasonRepository from "@/repositories/requestReasonRepository";

export default {
  name: "RequestLimitDateExtensionModal",

  data() {
    return {
      isLoading: false,
      dialog: false,
      limitDateExtensionRequest: {},
      REQUEST_CLASIFICATION_ID: 2,
      requestReasons: {
        isLoading: false,
        data: [],
      },
    };
  },

  props: {
    subEvaluationId: {
      type: Number,
    },
    userId: {
      type: Number,
    },
  },

  methods: {
    create() {
      this.isLoading = true;
      const payload = {
        ...this.limitDateExtensionRequest,
        user_id: this.userId,
        sub_evaluation_id: this.subEvaluationId,
      };
      limitDateExtensionRepository
        .createLimitDateExtensionRequest(payload)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data and hidding modal if operation has been completed
          if (data.status) {
            this.$emit("reload-data");
            this.closeModal();
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible procesar la solicitud 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.create();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.limitDateExtensionRequest = {};
    },

    getRequestReasons() {
      this.requestReasons.isLoading = true;
      requestReasonRepository
        .getRequestReasonsByRequestClasificationId(
          this.REQUEST_CLASIFICATION_ID
        )
        .then(({ data }) => {
          this.requestReasons.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.requestReasons.isLoading = false;
        });
    },
    goToMyRequests() {
      this.$router.push({
        name: 'my_correction_notes_extension_of_profiles_request'
      })
    },
  },
  validations: {
    limitDateExtensionRequest: {
      request_reason_id: {
        required,
      },
      justification: {
        required,
      },
    },
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.getRequestReasons();
      }
    },
  },
};
</script>
