<template>
  <div class="d-flex flex-row">
    <form-group
      :name="isModularSubject ? 'nivel de logro' : 'nota'"
      :validator="$v.score"
      :messages="errorMessages"
    >
      <v-text-field
        slot-scope="{ attrs }"
        v-bind="attrs"
        :background-color="score >= 1.0 ? 'light-blue lighten-5' : 'white'"
        color="red"
        dense
        placeholder="NPI"
        filled
        rounded
        class="mt-5 text--black text-h5 font-weight-medium"
        style="max-width: 145px;"
        type="number"
        elevation="1"
        min="0"
        step="0.1"
        v-model.trim="$v.score.$model"
        @change="
          formatScore();
          save();
        "
        @blur="
          formatScore();
          save();
        "
        :disabled="isOutOfTime"
      >
        <template v-if="isOutOfTime" v-slot:append-outer>
          <v-tooltip color="red lighten-1" bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="red lighten-1">
                mdi-clock-alert
              </v-icon>
            </template>
            {{
              `Periodo de ingreso de ${
                isModularSubject ? "niveles de logro" : "notas"
              } finalizado`
            }}
          </v-tooltip>
        </template>
      </v-text-field>
    </form-group>
    <div
      class="d-flex justify-center align-center mx-2"
      style="width: 24px;"
      v-show="canBeSubmitted"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        :size="20"
        :width="3"
        v-if="isLoading"
      ></v-progress-circular>
      <v-tooltip
        bottom
        :color="wasSaved ? 'success' : 'error'"
        v-if="!isLoading && canBeSubmitted"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            text
            :color="wasSaved ? 'success' : 'error'"
          >
            {{ wasSaved ? "mdi-checkbox-marked-circle" : "mdi-close-circle" }}
          </v-icon>
        </template>
        <span>
          {{ getTooltipLabel }}
        </span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import scoreStudentSubEvaluationRepository from "@/repositories/scoreStudentSubEvaluationRepository";

export default {
  name: "SubEvaluationScoreInput",
  data() {
    return {
      score: null,
      isLoading: false,
      canBeSubmitted: false,
      wasSaved: false,
    };
  },
  props: {
    subEvaluationScore: {
      type: String,
      default: "0.0"
    },
    subEvaluationScoreId: {
      type: Number,
    },
    minimumScore: {
      type: Number,
    },
    maximumScore: {
      type: Number,
    },
    isOutOfTime: {
      type: Boolean,
    },
    isModularSubject: {
      type: Boolean,
    },
  },
  methods: {
    save() {
      this.isLoading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        const errorMsg = this.isModularSubject
          ? "El nivel de logro ingresado no es válido"
          : "La nota ingresada no es válida";
        this.fireToast({
          icon: "error",
          title: errorMsg,
          timer: 2000,
        });
        this.isLoading = false;
        this.canBeSubmitted = false;
        return;
      }
      this.canBeSubmitted = true;

      const payload = {
        score: this.score,
      };
      scoreStudentSubEvaluationRepository
        .updateScoreStudentSubEvaluation(this.subEvaluationScoreId, payload)
        .then(({ data }) => {
          // Showing alert with response data
          if (!data.status) {
            this.fireToast({
              icon: "error",
              title: data.message,
            });
          }
          this.wasSaved = data.status;
          this.$emit("update-score", {
            subEvaluationScoreId: this.subEvaluationScoreId,
            score: this.score,
          });
        })
        .catch((err) => {
          console.error(err);
          this.fireToast({
            icon: "error",
            title: "No ha sido posible actualizar el registro 🙁",
          });
          this.wasSaved = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    formatScore() {
      if (isNaN(this.score)) {
        return;
      }
      this.score = parseFloat(this.score).toFixed(1);
    },
  },
  computed: {
    errorMessages() {
      return {
        minValue: `${
          this.isModularSubject
            ? "El nivel de logro mínimo es"
            : "La nota mínima"
        } es {min}`,
        maxValue: `${
          this.isModularSubject
            ? "El nivel de logro máximo es"
            : "La nota máxima"
        } es {max}`,
      };
    },
    getTooltipLabel() {
      if (this.isModularSubject) {
        return this.wasSaved
          ? "Nivel del logro guardado"
          : "Hubo un error al guardar el nivel de logro";
      } else {
        return this.wasSaved
          ? "Nota guardada"
          : "Hubo un error al guardar la nota";
      }
    }
  },
  validations() {
    return {
      score: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(this.maximumScore),
      },
    };
  },
  beforeMount() {
    if (this.subEvaluationScore == "0.0" || this.subEvaluationScore == null) {
      this.score = null;
      return
    }
    this.score = this.subEvaluationScore;
  }
};
</script>
