<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left> mdi-arrow-left </v-icon>
      Regresar al perfil
    </v-btn>

    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            {{ editMode ? "Ingreso de notas" : "Visualización de notas" }}
            <span class="d-block text-muted pt-2 font-size-sm">
              Aqui se podrá visualizar la lista de estudiantes de la sección o
              grupo seleccionado para el ingreso o visualización de notas
            </span>
          </h3>
        </div>
      </div>
      <!-- begin::SubEvaluation Info and Students Loader -->
      <div v-if="students.isLoading" style="height: 70vh">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            Cargando información de la evaluación y el listado de estudiantes
          </v-col>
          <v-col cols="7" sm="6">
            <v-progress-linear
              color="blue darken-1"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </div>
      <!-- end::SubEvaluation Info and Students Loader -->
      <div v-else class="card-body">
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="10" md="10" lg="8" xl="6">
            <SubEvaluationCardJustInfo
              :index="subEvaluation.id"
              :subEvaluation="subEvaluation"
              :evaluation="evaluation"
              :totalWeight="evaluation.totalWeight"
              :color="orderedColors[evaluation.id % 17]"
              @set-sub-evaluation="setSubEvaluation"
            ></SubEvaluationCardJustInfo>
          </v-col>
          <v-col>
            <!-- begin:: Stadistic Information: Group Info -->
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="pa-0">
                <v-sheet elevation="3" class="mb-3 px-3 py-2 rounded-lg">
                  <v-container>
                    <v-row>
                      <v-col>
                        Visualizando los estudiantes de
                        <span v-if="!isTechnicalGroup">la sección </span>
                        <br />
                        <span class="text-h4 font-weight-medium">{{
                          groupInformation.group
                        }}</span>
                        <br />
                        de
                        <span class="text-h6 font-weight-medium">{{
                          groupInformation.grade
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-sheet>
              </v-col>
              <!-- end:: Stadistic Information: Group Info -->

              <!-- begin:: Stadistic Information: Scores Info -->
              <v-col cols="12" class="pa-0">
                <v-sheet elevation="3" class="mb-3 px-3 py-2 rounded-lg">
                  <v-container>
                    <v-row>
                      <v-col>
                        {{ isTechnicalGroup ? "Nivel de logros" : "Nota" }}
                        promedio
                        <br />
                        <span class="text-h3 font-weight-bold">
                          {{ scoresInformation.averageScore || "0.00" }}
                        </span>
                        <br />
                        de
                        <span class="text-h6 font-weight-medium">
                          {{
                            `${scoresInformation.numOfStudentsEvaluated}/${scoresInformation.totalStudents}`
                          }}
                        </span>
                        estudiantes evaluados
                      </v-col>
                    </v-row>
                  </v-container>
                </v-sheet>
              </v-col>
            </v-row>
            <!-- end:: Stadistic Information: Scores Info -->
          </v-col>
        </v-row>

        <!-- begin::Out of time alert -->
        <v-row v-if="isOutOfTime && editMode" no-gutters class="mt-4">
          <!-- Alerta de periodo finalizado -->
          <v-col>
            <v-card outlined class="m-4 px-5 py-4 d-flex flex-column flex-sm-row rounded-lg justify-center align-start" color="red lighten-5">
              <v-icon x-large color="red">
                mdi-timer-alert
              </v-icon>
              <div class="px-4">
                <h1 class="red--text">El periodo hábil de ingreso de calificaciones para esta
                  evaluación ha finalizado.</h1>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="isOutOfTime && editMode" no-gutters>
          <!-- Extensión de fecha -->
          <v-col>
            <v-card outlined class="m-4 px-5 py-4 d-flex flex-column flex-sm-row rounded-lg justify-center align-start" color="teal lighten-5">
              <v-icon x-large color="teal">
                mdi-calendar-expand-horizontal
              </v-icon>
              <div class="px-4">
                <h1 class="teal--text">¿Deseas solicitar una extensión de fecha límite?</h1>
                <p class="m-0 text-body-1">
                  Es importante señalar que este cambio de fecha es independiente del grupo que estás consultando actualmente. Esto significa que si necesitas ingresar calificaciones para varios grupos, solo tendrás que procesar una solicitud de extensión única que será aplicable a todos los grupos.
                </p>
                <v-btn 
                  depressed 
                  color="teal" 
                  dark 
                  class="mt-2" 
                  v-if="
                    existInArray(
                      'Solicitar extensión de fecha límite',
                      currentPageActions
                    )
                  "
                  @click="showMdlRequestLimitDateExtension"
                >
                  Solicitar extensión
                </v-btn>
              </div>
            </v-card>
          </v-col>
          <!-- Corrección de nota -->
          <v-col>
            <v-card outlined class="m-4 px-5 py-4 d-flex flex-column flex-sm-row rounded-lg justify-center align-start" color="blue lighten-5">
              <v-icon x-large color="blue accent-4">
                mdi-bandage
              </v-icon>
              <div class="px-4">
                <h1 class="blue--text text--accent-4">¿Deseas realizar una correción de las calificaciones ingresadas anteriormente?</h1>
                <v-btn 
                  depressed 
                  color="blue accent-4" 
                  dark 
                  class="mt-2" 
                  v-if="
                    existInArray(
                      'Solicitar corrección de notas',
                      currentPageActions
                    )
                  "
                  @click="goToScoresAmendForm"
                >
                  Solicitar corrección
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="pb-0">
            <v-card outlined class="m-4 px-5 py-4 d-flex flex-column flex-sm-row rounded-lg justify-center align-start" color="indigo lighten-5">
              <v-icon x-large color="indigo">
                mdi-book-information-variant
              </v-icon>
              <div class="px-4">
                <h1 class="indigo--text">¡Atención!</h1>
                <p class="m-0 text-body-1">
                  Es necesario que ingrese todas las notas de esta evaluación ya que el 1 que se muestra al crear un perfil es un dato almacenado de forma predeterminada en el sistema, y de no hacerlo, la evaluación se mostrará como <span class="font-weight-bold">N.P.I (Nota Pendiente de Ingresar)</span> en el caso que la nota obtenida por el estudiante sea 1 siempre se debe ingresar ese 1 en el campo correspondiente.
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <!-- end::Out of time alert -->
        <!-- begin::Info about limit date extension -->
        <v-row v-if="hasLimitDateExtension && !isOutOfTime">
          <v-col class="pb-0">
            <v-card outlined class="m-4 px-5 py-4 d-flex flex-column flex-sm-row rounded-lg justify-center align-start" color="orange lighten-5">
              <v-icon x-large color="orange">
                mdi-timer-lock-open
              </v-icon>
              <div class="px-4">
                <h2 class="black--text text--accent-4">El periodo hábil de ingreso de calificaciones para esta
                  evaluación ha sido extendido, la nueva fecha límite para
                  ingresar calificaciones es el
                  <span class="font-weight-bold">
                    {{
                      subEvaluation.limit_date_extension_detail
                        .new_limit_date_formatted
                    }} </span
                  >.</h2>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <!-- end::Info about limit date extension -->

        <v-row>
          <v-col>
            <v-sheet class="d-none d-sm-flex flex-row align-center">
              <v-container>
                <v-row no-gutters>
                  <v-col
                    class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                    sm="1"
                  >
                    Foto
                  </v-col>
                  <v-col
                    class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                    sm="3"
                  >
                    Código
                  </v-col>
                  <v-col
                    class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                    sm="3"
                  >
                    Nombre completo
                  </v-col>
                  <v-col
                    class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                    sm="2"
                  >
                    Especialidad
                  </v-col>
                  <v-col
                    class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                    sm="3"
                  >
                    {{
                      evaluation.subject_type_id == 1
                        ? "Nivel de logro obtenido"
                        : "Nota obtenida"
                    }}
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
            <v-hover
              v-slot="{ hover }"
              v-for="(student, index) in students.data"
              :key="student.code"
            >
              <v-sheet
                :elevation="hover ? 5 : 0"
                class="d-flex flex-column flex-sm-row align-md-center mb-1"
                :color="
                  editMode
                    ? `blue lighten-${index % 2 ? 3 : 4}`
                    : `grey lighten-${index % 2 ? 3 : 5}`
                "
                rounded="xl"
              >
                <v-container class="py-0">
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      sm="1"
                      class="d-flex justify-center align-center mt-5 mt-sm-0"
                    >
                      <v-avatar size="60">
                        <img
                          :src="`${student.photo}`"
                          :alt="`${student.full_name}`"
                        />
                      </v-avatar>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      class="d-flex justify-center align-center text-h4 font-weight-medium pr-sm-3 mt-4 mt-sm-0"
                    >
                      {{ student.code }}
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      class="d-flex justify-center justify-sm-start align-center text-body-1 font-weight-medium text-uppercase mb-2 mb-sm-0"
                    >
                      <div>
                        {{ student.full_name }}
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="2"
                      class="d-flex justify-center align-center pa-0 ma-0"
                    >
                      {{ student.technical_group }}
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      class="d-flex justify-center align-center pa-0 ma-0"
                    >
                      <SubEvaluationScoreInput
                        v-if="editMode"
                        :subEvaluationScore="student.score"
                        :subEvaluationScoreId="student.sub_evaluation_score_id"
                        :minimumScore="minimumScoreAllowed"
                        :maximumScore="maximumScoreAllowed"
                        :isOutOfTime="isOutOfTime"
                        :isModularSubject="isModularSubject"
                        @update-score="updateStudentScore"
                      >
                      </SubEvaluationScoreInput>
                      <span class="text-h5 font-weight-medium my-3 mr-1" v-else>
                        {{ currentScore(student.score) }}
                      </span>
                      <v-tooltip
                        v-if="!student.is_updated_score"
                        bottom
                        color="red darken-1"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            color="red lighten-4"
                            :class="`${editMode ? 'ml-0 mb-0' : 'ml-1'} `"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="red darken-1" center>
                              mdi-account-badge-outline
                            </v-icon>
                          </v-chip>
                        </template>
                        <span>Estudiante no evaluado aún</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
            </v-hover>
          </v-col>
        </v-row>
      </div>
    </div>

    <RequestLimitDateExtensionModal
      ref="mdlRequestLimitDateExtension"
      :subEvaluationId="subEvaluationId"
      :userId="currentUserPersonalInfo.id_user"
    ></RequestLimitDateExtensionModal>
  </div>
</template>

<script>
import studentRepository from "@/repositories/studentRepository";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SubEvaluationCardJustInfo from "@/components/elements/evaluation/SubEvaluationCardJustInfo.vue";
import SubEvaluationScoreInput from "@/components/elements/sub_evaluation_scores/SubEvaluationScoreInput.vue";
import RequestLimitDateExtensionModal from "@/components/modals/requests/RequestLimitDateExtensionModal.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";

export default {
  name: "SubEvaluationScores",
  title: "Ingreso de notas | GE ITR",
  components: {
    RequestLimitDateExtensionModal,
    SubEvaluationScoreInput,
    SubEvaluationCardJustInfo,
  },

  data() {
    return {
      isGradedStudent: false,
      students: {
        headers: [
          { text: "Código", value: "code" },
          { text: "Nombres", value: "first_name" },
          { text: "Apellidos", value: "last_name" },
          { text: "Nota asignada", value: "score" },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: {},
        expanded: [],
        selectedSubEvaluation: {},
      },
      evaluation: {},
      subEvaluation: {},
      groupInformation: {},
    };
  },

  props: {
    // f de from para identificar desde que tipo de grupo viene, se acepta "t-g" o "a-g"
    f: {
      type: String,
    },
    // m para identificar si es el modo de ver(v) o editar(e)
    m: {
      type: String,
    },
    // g para el identificador del grupo independiente a que si es técnico o académico
    g: {
      type: String,
    },
    // S_e(subEvaluation) para el identificador de la evaluación
    s_e: {
      type: String,
    },
    // s para la sección en dado caso sea grupo técnico
    s: {
      type: String,
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mis asignaturas", route: "teacher_subjects" },
      { title: "Perfiles de la asignatura", route: "subject_evaluations" },
    ]);
    this.loadStudents();
    this.$store.dispatch(
      GET_CURRENT_PAGE_ACTIONS,
      "Visualización e ingreso de notas"
    );
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    setSubEvaluation(subEvaluation) {
      this.selectedSubEvaluation = { ...subEvaluation };
    },
    orderedColors() {
      return this.colors.sort(() => Math.random() - 0.5);
    },
    loadStudents() {
      this.students.isLoading = true;
      let fetchFn, payload;
      if (this.f == "a-g") {
        fetchFn = studentRepository.getStudentsScoresByAcademicGroup;
        payload = {
          academic_group_id: this.g,
          sub_evaluation_id: this.s_e,
        };
      } else if (this.f == "t-g") {
        fetchFn = studentRepository.getStudentsScoresByTechnicalGroup;
        payload = {
          technical_group_id: this.g,
          sub_evaluation_id: this.s_e,
          section: this.s,
        };
      }

      fetchFn(payload)
        .then(({ data }) => {
          this.students.data = data.students;
          this.evaluation = data.sub_evaluation.evaluation;
          this.subEvaluation = data.sub_evaluation;
          this.groupInformation = data.group_information;
        })
        .catch((err) => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.students.isLoading = false;
        });
    },
    updateStudentScore({ subEvaluationScoreId, score }) {
      const studentData = this.students.data.find(
        (item) => item.sub_evaluation_score_id == subEvaluationScoreId
      );
      const index = this.students.data.indexOf(studentData);

      if (index != -1) {
        this.students.data[index].score = score;
        this.students.data[index].is_updated_score = true;
      }
    },
    goToScoresAmendForm() {
      this.$router.push({
        path: "scores_amend_form",
        query: {
          f: this.f, // f inicial de from, a-g de technicalGroup
          g: this.g, // g  inicial de group
          s_e: this.s_e, // e inicial de evaluation
          s: this.s,
        },
      });
    },
    showMdlRequestLimitDateExtension() {
      this.$refs.mdlRequestLimitDateExtension.toggleModal();
    },

    currentScore(score) {
      if (score != "0.0") {
        return score;
      }
      return "NPI"
    },
    goToMyRequests() {
      this.$router.push({
        path: 'my_requests'
      })
    },
  },
  computed: {
    ...mapGetters([
      "currentPageActions",
      "userType",
      "currentUserPersonalInfo",
    ]),
    editMode() {
      return this.m == "e";
    },
    minimumScoreAllowed() {
      const scoreAllowed = this.evaluation.minimum_score || 1.0;
      const parsedScoreAllowed = parseFloat(scoreAllowed);
      return parsedScoreAllowed;
    },
    maximumScoreAllowed() {
      const scoreAllowed = this.evaluation.maximum_score || 10.0;
      const parsedScoreAllowed = parseFloat(scoreAllowed);
      return parsedScoreAllowed;
    },
    isOutOfTime() {
      if (!("is_out_of_time" in this.subEvaluation)) {
        return false;
      }
      return this.subEvaluation.is_out_of_time;
    },
    isTechnicalGroup() {
      return this.f == "t-g";
    },
    scoresInformation() {
      // Obteniendo los estudiantes con una nota mayor a 1, es decir, los que han sido evaluados
      const evaluatedStudent = this.students.data.filter(
        (item) => parseFloat(item.score) >= 1.0
      );
      // Se guarda la longitud para saber cuantos son
      const numOfStudentsEvaluated = evaluatedStudent.length;

      // Se obtiene la sumatoria de las notas(solo se toman en cuenta las que son mayor que 1)
      const sum = this.students.data.reduce((prev, curr) => {
        const score = parseInt(curr.score);
        if (score >= 1) {
          return prev + score;
        }
        return prev;
      }, 0);
      // se calcula y se le da formato al promedio
      const averageScore =
        sum > 0 && numOfStudentsEvaluated > 0
          ? parseFloat(sum / numOfStudentsEvaluated).toFixed(1)
          : "0.0";

      return {
        averageScore,
        numOfStudentsEvaluated,
        totalStudents: this.students.data.length,
      };
    },
    isModularSubject() {
      return this.evaluation.subject_type_id == 1;
    },
    subEvaluationId() {
      return parseInt(this.s_e);
    },
    hasLimitDateExtension() {
      return !!this.subEvaluation.limit_date_extension_detail;
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>

<style scoped>
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: #bdbdbd;
}
</style>
