<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        class="ma-0 mx-auto px-n5 pa-sm-3 transition-swing rounded-lg"
        :class="`elevation-${hover ? 5 : 3}`"
      >
        <!--sub-evaluacion name-->
        <!-- colored headers -->
        <v-row class="ma-0 pa-0 mb-2 d-flex justify-center align-center">
          <v-col
            cols="12"
            sm="7"
            class="pt-sm-0 d-flex justify-center mb-0 pb-sm-0 justify-sm-start"
          >
            <h4 class="card-title text-center ma-0 my-auto">
              {{ evaluation.activity_type }}
            </h4>
            <v-chip color="primary" small class=" ml-2">{{
              evaluation.percent_formatted
            }}</v-chip>
          </v-col>
          <!-- begin::sub-evaluation weight -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex justify-center justify-sm-end ma-0 mt-md-0 pa-0"
          >
            <p class="ma-0 text-body-1">
              {{
                "evaluation" in subEvaluation
                  ? subEvaluation.evaluation.activity_category
                  : ""
              }}
            </p>
          </v-col>
          <v-col sm="1" class="d-flex justify-center">
            <v-menu
              open-on-hover
              left
              top
              offset-x
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>

              <v-card class="pa-4" style="width:300px !important">
                <p class="text-h6 ma-0 mb-2">Descripción:</p>
                <p class="text-body-1 ma-0">{{ evaluation.description }}</p>
              </v-card>
            </v-menu>
          </v-col>
          <!-- end::sub-evaluation weight -->
        </v-row>
        <!-- end::colored header -->

        <!-- begin::deadline -->
        <v-row class="ma-0 pa-0">
          <v-col
            cols="12"
            class="d-flex text-center text-sm-left justify-center justify-sm-left ma-0 ml-0 pa-0 flex-sm-row flex-column"
          >
            <v-tooltip bottom :color="`${color} darken-1`">
              <template v-slot:activator="{ on, attrs }">
                <p class=" ma-0 pa-0" v-bind="attrs" v-on="on">
                  <v-icon :color="`${color} lighten-3`"
                    >mdi-calendar-start</v-icon
                  >
                  {{ evaluation.start_date_formatted }}
                  <v-divider class="ma-1 d-sm-none"></v-divider>
                </p>
                <p class=" ml-sm-5 ma-0 pa-0" v-bind="attrs" v-on="on">
                  <v-icon :color="`${color} lighten-3`"
                    >mdi-calendar-end</v-icon
                  >
                  {{ evaluation.end_date_formatted }}
                </p>
              </template>
              <span class="text-center">Período de evaluación</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <!-- end::deadline -->

        <!-- begin::sub-evaluation description -->
        <v-row class="ma-0 pa-4">
          <v-col
            cols="12"
            class="blue-grey lighten-5 rounded-lg elevation-2 px-0 px-sm-2 pt-3"
          >
            <v-row class="mx-2">
              <v-col
                cols="12"
                sm="7"
                class="pt-sm-0 mb-0 pb-sm-0 d-flex justify-center justify-sm-start flex-sm-row flex-column "
              >
                <h4 class="card-title text-center ma-0 my-auto">
                  {{ subEvaluation.evaluation_type }}
                </h4>
                <v-chip
                  color="primary"
                  small
                  class="mt-2 my-sm-auto ml-sm-2 d-flex justify-center"
                >
                  <p
                    class="ma-0 text-center white--text text-body-1 font-weight-medium"
                  >
                    {{
                      `${subEvaluation.percent}% de ${evaluation.percent_formatted}`
                    }}
                  </p>
                </v-chip>
              </v-col>
              <v-col
                cols="12"
                sm="5"
                class="d-flex justify-center justify-sm-end px-0"
              >
                <v-tooltip top :color="`${color} darken-1`">
                  <template v-slot:activator="{ on, attrs }">
                    <p class=" ml-sm-0 ma-0 pa-0" v-bind="attrs" v-on="on">
                      <v-icon :color="`${color} lighten-3`"
                        >mdi-clock-end</v-icon
                      >
                      {{ subEvaluation.limit_date_formatted }}
                    </p>
                  </template>
                  <span class="text-center">Fecha límite de evaluación</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0 my-2">
              <v-col cols="12">
                <p
                  class=" ma-0 pa-2 px-3 white rounded text-body-1"
                  v-if="subEvaluation.description"
                >
                  {{ subEvaluation.description }}
                </p>
                <p class="ma-0 pa-0 px-3" v-else>
                  No hay descripción de actividad.
                </p>
              </v-col>
            </v-row>
            <!-- begin::Sub-evaluation instrument evaluation -->
            <v-row
              v-if="subEvaluation.evaluation_instrument_document_path != null"
            >
              <v-col class="p-0">
                <a
                  :href="evaluationInstrumentURL + subEvaluation.idE"
                  target="_blank"
                  class="mx-4 mb-3 d-flex justify-center align-center"
                >
                  <v-icon class="mr-1" color="red">mdi-file-pdf-box</v-icon>
                  <span class="font-weight-medium black--text"
                    >Instrumento de evaluación</span
                  >
                </a>
              </v-col>
            </v-row>
            <!-- end::Sub-evaluation instrument evaluation -->
          </v-col>
        </v-row>
        <!-- end::sub-evaluation description -->
      </v-card>
    </template>
  </v-hover>
</template>

<script>
export default {
  name: "subEvaluationCardJustInfo",
  components: {},
  data() {
    return {};
  },
  props: {
    subEvaluation: {
      type: Object,
      default: () => {},
    },
    evaluation: {
      type: Object,
    },
    totalWeight: {
      type: String,
    },
    color: {
      type: String,
    },
  },
  methods: {
    setSubEvaluation() {
      this.$emit("set-sub-evaluation", this.subEvaluation);
    },
  },
  computed: {
    evaluationInstrumentURL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DEV_API_URL;
      } else {
        baseURL = process.env.LOCAL_API_URL;
      }
      return baseURL + "/sub-evaluations/evaluation-instrument/";
    },
  },
};
</script>

<style>
.fixed-height {
  height: 100px;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding: 5px 10px;
}
</style>
